import { useEffect } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
// import { SiWindows11 } from "react-icons/si";
const QuestionChange = ({
  quizTestsQuestions = [],
  setCurrentPage,
  currentPage,
  answers,
}) => {
  console.log(currentPage);
  const itemsPerPage = 1;
  const totalPages = Math.ceil(quizTestsQuestions.length / itemsPerPage);
  const maxVisibleButtons = window.innerWidth <= 768 ? 5 : totalPages;

  const isAnswered = (questionId) => {
    return answers.some(
      (answer) =>
        answer.questionId === questionId && answer.selectedAnswer !== null,
    );
  };

  const isFlagged = (questionId) => {
    return answers.some(
      (answer) => answer.questionId === questionId && answer.flag,
    );
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getQuestionStatus = (index) => {
    const questionId = quizTestsQuestions[index]?._id; // Assuming each question has an ID
    let pageClass = "default-color"; // Default class for unvisited/unanswered questions

    if (isAnswered(questionId)) {
      pageClass = "green-color"; // Answered questions
    } else if (isFlagged(questionId)) {
      pageClass = "red-color"; // Flagged questions
    }
    return pageClass;
  };

  const getPageNumbers = () => {
    if (totalPages <= maxVisibleButtons) return [...Array(totalPages).keys()];

    const middleIndex = Math.floor(maxVisibleButtons / 2);
    if (currentPage <= middleIndex) {
      return [...Array(maxVisibleButtons - 1).keys(), "...", totalPages - 1];
    } else if (currentPage >= totalPages - middleIndex) {
      return [0, "...", ...Array(maxVisibleButtons - 1).keys()].map((num, i) =>
        num === "..." ? "..." : totalPages - maxVisibleButtons + i,
      );
    } else {
      return [
        0,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        totalPages - 1,
      ];
    }
  };

  return (
    <div className='row mx-0'>
      <div className='col-lg-12 d-flex align-items-center justify-content-center gap-2'>
        <div className='pagination d-flex'>
          <span
            className='page-link border rounded pt-0 fs-5'
            onClick={handlePreviousPage}
            style={{ cursor: currentPage === 0 ? "not-allowed" : "pointer" }}
          >
            <FaAngleLeft />
          </span>

          {getPageNumbers().map((num, index) => (
            <span
              key={index}
              className={`page-link ${
                num === "..." ? "disabled" : getQuestionStatus(num)
              }
              ${currentPage === num ? "active" : ""}`}
              onClick={() => num !== "..." && setCurrentPage(num)}
              style={{ cursor: num === "..." ? "default" : "pointer" }}
            >
              {num === "..." ? "..." : num + 1}
            </span>
          ))}

          <span
            className='page-link border rounded pt-0 fs-5'
            onClick={handleNextPage}
            style={{
              cursor:
                currentPage === totalPages - 1 ? "not-allowed" : "pointer",
            }}
          >
            <FaAngleRight />
          </span>
        </div>
      </div>
    </div>
  );
};

export default QuestionChange;
