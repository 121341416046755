const AssessmentOptions = ({
  handleOptionChange,
  quizTestsQuestions,
  currentPage,
  answers,
}) => {
  const { _id, option1, option2, option3, option4 } =
    (quizTestsQuestions && quizTestsQuestions[currentPage]) || {};

  const selectedAnswer = answers.find(
    (answer) => answer.questionId === _id || null,
  )?.selectedAnswer;

  const optionLetters = ["A", "B", "C", "D"];

  return (
    <div className='row mx-0'>
      <div className='col-12 px-0 py-2'>
        <div className='row mx-0'>
          <div className='col-12'>
            <div className='pb-2'>
              <h5 className='d-none d-lg-block'>Instructions</h5>
            </div>
            <div className='options'>
              {[option1, option2, option3, option4].map((option, optIndex) => {
                const isSelected = selectedAnswer === option;
                return (
                  <div className='mb-3'>
                    <div
                      className={`option-block ${
                        isSelected ? "border-primary" : ""
                      }`}
                      key={optIndex}
                      onClick={() => handleOptionChange(_id, option)} // Click on the block to select
                    >
                      <div className='form-check d-flex align-items-center py-3'>
                        <div className='ps-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name={`question-${_id}`}
                            value={option}
                            checked={selectedAnswer === option}
                            onChange={() => handleOptionChange(_id, option)}
                            id={`question-${_id}-option-${optIndex}`}
                          />
                        </div>
                        <label
                          className={`form-check-label text-start py-0 ${
                            isSelected ? "text-primary" : ""
                          }`}
                          htmlFor={`question-${_id}-option-${optIndex}`}
                        >
                          {option}
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentOptions;
