import React from "react";
import parse from "html-react-parser";
import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrowNight } from "react-syntax-highlighter/dist/cjs/styles/hljs";

const AssessmentQuestions = ({ quizTestsQuestions, currentPage }) => {
  const questionIndex = Array.isArray(quizTestsQuestions) ? currentPage : 0;
  const currentQuestion = Array.isArray(quizTestsQuestions)
    ? quizTestsQuestions[questionIndex]
    : {};
  const questionTitle = currentQuestion?.title || "No title available";

  const renderContent = (htmlString) => {
    if (!htmlString) return null;

    return parse(htmlString, {
      replace: (domNode) => {
        if (domNode.name === "pre") {
          const codeContent = domNode.children
            .map(
              (child) =>
                child.data ||
                (child.children
                  ? child.children.map((c) => c.data).join("")
                  : ""),
            )
            .join("\n");

          return (
            <SyntaxHighlighter style={tomorrowNight} >
              {codeContent}
            </SyntaxHighlighter>
          );
        }
      },
    });
  };

  return (
    <div className='row mx-0'>
      <div className='col-12 px-0 py-2'>
        <div className='row mx-0'>
          <div className='col-12 pb-2'>
            <h5>{`Question ${questionIndex + 1}`}</h5>
          </div>
          <div className='col-12'>{renderContent(questionTitle)}</div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentQuestions;
