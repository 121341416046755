import { useEffect, useState } from "react";
import {
  getQuizTestDetails,
  submitQuizTest,
} from "../../../quiz_board/actions";
import PlayboardHeader from "./PlayboardHeader";
import PlaygroundBody from "./PlaygroundBody";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../shared_components/Loader";
import { Spin } from "antd";

const QuizPlayboard = () => {
  const { currentQuizTest, quizTestDataLoading, quizTestSubmittingLoading } =
    useSelector(({ quizTest }) => quizTest) || {};
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [isTestActive, setIsTestActive] = useState(true);
  const quizTestsQuestions = currentQuizTest?.quizQuestions;
  const cmpLogo = currentQuizTest?.cmpLogoUrl;

  const [currentPage, setCurrentPage] = useState(0);
  const [answers, setAnswers] = useState([]); // Answers state
  const [flaggedQuestions, setFlaggedQuestions] = useState([]);

  // Load answers from localStorage on page load
  useEffect(() => {
    const storedAnswers = localStorage.getItem(`quiz-${id}-answers`);
    if (storedAnswers) {
      setAnswers(JSON.parse(storedAnswers));
    }

    // Fetch quiz test details
    dispatch(getQuizTestDetails({ quizTest: id, mode: "test" }));
  }, [id]); // Runs when `id` changes

  const handleTimeUp = () => {
    setIsTestActive(false);
    handleSubmit();
  };

  const handlePageChange = (action) => {
    if (action === "Next" && currentPage < quizTestsQuestions.length - 1) {
      setCurrentPage(currentPage + 1);
    } else {
      if (currentPage > 0) {
        setCurrentPage(currentPage - 1);
      }
    }
  };

  const handleOptionChange = (questionId, selectedAnswer) => {
    const updatedAnswers = [...answers];
    const index = updatedAnswers.findIndex(
      (answer) => answer.questionId === questionId,
    );

    if (index > -1) {
      updatedAnswers[index].selectedAnswer = selectedAnswer;
    } else {
      updatedAnswers.push({ questionId, selectedAnswer });
    }
    // Save the updated answers to localStorage
    localStorage.setItem(`quiz-${id}-answers`, JSON.stringify(updatedAnswers));

    setAnswers(updatedAnswers);
  };

  const handleFlagQuestion = (questionId) => {
    const updatedAnswers = [...answers];
    const index = updatedAnswers.findIndex(
      (answer) => answer.questionId === questionId,
    );

    if (index > -1) {
      updatedAnswers[index].flag = !updatedAnswers[index].flag;
    } else {
      updatedAnswers.push({ questionId, flag: true });
    }
    // Save the updated answers to localStorage
    localStorage.setItem(`quiz-${id}-answers`, JSON.stringify(updatedAnswers));

    setAnswers(updatedAnswers);
  };

  const handleSubmit = async () => {
    try {
      const response = {
        quizTestId: id,
        answers: answers,
      };
      dispatch(submitQuizTest(response)).then((res) => {
        if (res) {
          const access_token = localStorage.getItem("access_token");
          const userDetails = localStorage.getItem("userDetails");
          localStorage.clear();
          localStorage.setItem("access_token", access_token);
          localStorage.setItem("userDetails", userDetails);
          navigate(`/${id}/report`);
        }
      });
    } catch (error) {
      alert("There was an error submitting your quiz.");
    }
  };

  return (
    <>
      {quizTestSubmittingLoading ? (
        <div className='mt-5 pt-5 text-center generating-report-container'>
          <h3 className='generating-report-title'>Generating Report...</h3>
          <Loader />
        </div>
      ) : (
        <>
          {quizTestsQuestions && (
            <>
              <div>
                <PlayboardHeader
                  handleTimeUp={handleTimeUp}
                  isTestActive={isTestActive}
                  handleSubmit={handleSubmit}
                  quizTime={currentQuizTest?.duration}
                  cmpLogo={cmpLogo}
                />
              </div>
              <div>
                <PlaygroundBody
                  handleFlagQuestion={handleFlagQuestion}
                  isTestActive={isTestActive}
                  answers={answers}
                  flaggedQuestions={flaggedQuestions}
                  currentPage={currentPage}
                  quizTestsQuestions={quizTestsQuestions}
                  handleOptionChange={handleOptionChange}
                  handlePageChange={handlePageChange}
                  setCurrentPage={setCurrentPage}
                  handleSubmit={handleSubmit}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default QuizPlayboard;
