import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../../../shared_components/Loader";
import { Spin } from "antd";

const AssessmentCardVerified = ({ quizTestsList }) => {
  const { id } = useParams();
  const testUser = JSON.parse(localStorage.getItem("userDetails"));
  const reports = testUser?.data?.quizReports || [];
  const { quizTestsListLoading } = useSelector((state) => state.quizTest);
  const reportedQuizIds = new Set(reports.map((report) => report.quizTest));

  const filteredQuizTests = quizTestsList?.filter(
    (item) =>
      item.quizCategory.toLowerCase() === "assessment" &&
      !reportedQuizIds.has(item._id),
  );

  return (
    <div className='row mx-0'>
      <div className='col-12 px-0'>
        <div className='row mx-0'>
          <h6 className='ms-lg-2 fw-bolder fs-5'>Available Tests</h6>
          {quizTestsListLoading ? (
            <div className='text-center'>
              <Spin />
            </div>
          ) : (
            <div className='col-lg-12 col-12 d-lg-flex flex-lg-wrap'>
              {filteredQuizTests && filteredQuizTests.length > 0 ? (
                filteredQuizTests.map((item, index) => {
                  const hasQuestions = item.quizQuestions?.length !== 0;
                  return (
                    <div key={index} className='Assessment-card-manage'>
                      <div className='card rounded m-lg-2 mb-2'>
                        <div className='card-body'>
                          <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 flex-row'>
                              {/* Uncomment if you have the image */}
                              {/* <img
                                src={item.img}
                                alt="img"
                                className="card-img w-25"
                                height={30}
                              /> */}
                              <p className='py-0 fw-bold text-capitalize'>
                                {item.quizTitle}
                              </p>
                            </div>
                            <div>
                              <Link
                                to={
                                  hasQuestions
                                    ? `/assessments/${item._id}`
                                    : "/assessments"
                                }
                                onClick={(e) => {
                                  if (!hasQuestions) {
                                    e.preventDefault();
                                  }
                                }}
                              >
                                <button
                                  className='btn btn-outline-primary fs-6 fw-bold'
                                  disabled={!hasQuestions}
                                >
                                  Get Verified
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className='col-12 text-center mt-3'>
                  <p>No Assessments available !!</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssessmentCardVerified;
