import axios from "axios";
import * as actions from "./actionCreators";
import DatacodeConfig from "../../../core/config";
import * as constants from "../constants";
import { scheduleTokenExpiryLogout } from "../../../utils";

const baseURL = DatacodeConfig.baseURL;

export const setTestUser = (user) => (dispatch) => {
  dispatch(actions.setLoginUser(user));
};

export const loginUser = (user) => (dispatch) => {
  dispatch({ type: constants.GET_LOGIN_USER_LOADING });
  return axios
    .post(`${baseURL}/user/login`, user)
    .then((res) => {
      if (res.status === 200) {
        if (res.data.success) {
          dispatch({ type: constants.GET_LOGIN_USER_LOADING });
          dispatch(actions.setLoginUser(res.data));
          return { success: true, data: res.data };
        } else {
          dispatch({ type: constants.GET_LOGIN_USER_LOADING });
          return { success: false, data: res.data };
        }
      }
    })
    .catch((error) => {
      dispatch(actions.setLoginUserError(error));
      console.log("Login error", error);
    });
};

export const signupUser = (user) => (dispatch) => {
  return axios
    .post(`${baseURL}/user/signup`, user)
    .then((res) => {
      if (res.status === 200) {
        dispatch(actions.setSignupUser(res.data));
        console.log("Signed yu", res.data);
        return { success: true, data: res.data };
      }
    })
    .catch((error) => {
      dispatch(actions.setSignupUserError(error));
      console.log("Signup error", error);
    });
};

export const forgotPassword = (user) => (dispatch) => {
  return axios
    .post(`${baseURL}/user/forgot-password`, user)
    .then((res) => {
      if (res.status === 200) {
        console.log("Forgot Password", res.data);
        return { success: true, data: res.data };
      }
    })
    .catch((error) => {
      console.log("Forgot Password error", error);
    });
};

export const checkAuth =
  (token, setIsAuthenticated, handleAutoLogout) => (dispatch) => {
    return axios
      .post(
        `${baseURL}/user/validate-token`,
        { token },
        { withCredentials: true },
      )
      .then((response) => {
        if (response.status === 200) {
          scheduleTokenExpiryLogout(token, handleAutoLogout);
          setIsAuthenticated(true);
          return { success: true, data: response.data.user };
        }
      })
      .catch((error) => {
        console.error("Authentication error:", {
          message: error.message || "Unknown error occurred",
          status: error.response?.status,
          data: error.response?.data,
          stack: error.stack,
        });

        setIsAuthenticated(false);
        return {
          success: false,
          error: error.response?.data || "Request failed",
        };
      });
  };
