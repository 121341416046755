import NextButton from "./NextButton";
import QuestionChange from "./QuestionChange";

const QuizBodyHeader = ({
  flaggedQuestions,
  answers,
  currentPage,
  quizTestsQuestions,
  handlePageChange,
  setCurrentPage,
  handleSubmit,
}) => {
  return (
    <>
      <section>
        <div className='row mx-0'>
          <div className='col-12 px-0 pe-2'>
            <div className='row  mx-0 d-flex py-2 justify-content-between justify-content-md-around align-items-center Quiz-section'>
              <div className='col-2 col-lg-2 col-md-2 px-0 d-none d-md-block'>
                {currentPage > 0 && (
                  <NextButton
                    currentPage={currentPage}
                    name={"Prev"}
                    handlePageChange={handlePageChange}
                  ></NextButton>
                )}
              </div>
              <div className='col-5 col-lg-5 col-md-5 px-0 mx-auto'>
                <QuestionChange
                  currentPage={currentPage}
                  answers={answers}
                  flaggedQuestions={flaggedQuestions}
                  quizTestsQuestions={quizTestsQuestions}
                  handlePageChange={handlePageChange}
                  setCurrentPage={setCurrentPage}
                ></QuestionChange>
              </div>
              <div className=' col-2 col-lg-2  col-md-2 text-end px-0 d-none d-md-block'>
                {currentPage < quizTestsQuestions.length - 1 ? (
                  <NextButton
                    currentPage={currentPage}
                    totalQuestions={quizTestsQuestions?.length - 1}
                    name={"Next"}
                    handlePageChange={handlePageChange}
                  ></NextButton>
                ) : (
                  <button
                    onClick={() => handleSubmit()}
                    className='btn btn-outline-primary text-center px-lg-3 '
                  >
                    Finish
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default QuizBodyHeader;
