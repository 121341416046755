import { Link } from "react-router-dom";
import { Dropdown, Menu, Modal, Button } from "antd";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { deleteQuizTest, getQuizTestsList } from "../../actions";
import { useDispatch } from "react-redux";
import { useState } from "react";

const QuizTestCard = ({
  item,
  index,
  disable = false,
  certificateUrl,
  prev = false,
  ispassed,
}) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const showDeleteConfirm = (id) => {
    setSelectedItemId(id);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    dispatch(deleteQuizTest(selectedItemId)).then(() => {
      dispatch(getQuizTestsList());
      setIsModalVisible(false);
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key='edit'>Edit</Menu.Item>
      <Menu.Item
        danger
        key='delete'
        onClick={() => showDeleteConfirm(item._id)}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className='border quiz-card'>
        <div className='quiz-card-header'>
          {disable ? (
            <>
              <h6 className='quiz-title'>{item.quizTitle}</h6>
              {prev && (
                <span
                  className={`status-badge ${ispassed ? "passed" : "failed"}`}
                >
                  {ispassed ? "Passed" : "Failed"}
                </span>
              )}
            </>
          ) : (
            <Link to={`/quiz/test/${item._id}`} className='quiz-title'>
              {item.quizTitle}
            </Link>
          )}
        </div>

        {!prev && (
          <div className='quiz-card-details'>
            <span>
              <i
                className={
                  item.access === "public" ? "bi bi-globe" : "bi bi-envelope"
                }
              ></i>{" "}
              {item.access}
            </span>
            <span>
              <i className='bi bi-clock'></i> {item.duration} min
            </span>
            <span>
              <i className='bi bi-calendar4'></i> {item.startDate} -{" "}
              {item.endDate}
            </span>
          </div>
        )}

        <div className='quiz-card-footer'>
          {certificateUrl && (
            <a href={certificateUrl} className='btn-link'>
              View Candidate Report
            </a>
          )}

          <div className='quiz-card-actions'>
            {item.previewLink && (
              <a href='#' className='btn-action'>
                <i className='bi bi-eye'></i> Preview Test
              </a>
            )}
            {item.addinvite && (
              <a href='#' className='btn-action'>
                <i className='bi bi-person-plus'></i> Invite Candidates
              </a>
            )}
            {item.CanArchieve && (
              <a href='#' className='btn-action'>
                <i className='bi bi-archive'></i> Archive
              </a>
            )}
          </div>
        </div>

        <div className='text-start quiz-card-button'>
          {disable ? (
            <button className=' quiz-title primary-btn'>
              Already Submitted
            </button>
          ) : (
              <Link
              to={`/quiz/test/${item._id}`}
              className='quiz-title primary-btn'
            >
              Start Quiz
            </Link>
          )}
        </div>
      </div>

      <Modal
        title='Confirm Deletion'
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Yes'
        cancelText='No'
      >
        <p>Are you sure you want to delete this quiz test?</p>
      </Modal>
    </>
  );
};

export default QuizTestCard;
