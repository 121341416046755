import QuizBodyOptions from "./QuizBodyOptions";
import QuizBodyHeader from "./QuizBodyHeader";
import QuizBodyQuestion from "./QuizBodyQuestion";
import NextButton from "./QuizBodyHeader/NextButton";

const PlaygroundBody = ({
  handleFlagQuestion,
  setCurrentPage,
  flaggedQuestions,
  isTestActive,
  answers,
  currentPage,
  quizTestsQuestions,
  handleOptionChange,
  handlePageChange,
  handleSubmit,
}) => {
  return (
    <section className='px-0 px-md-3 '>
      <div
        className='mx-0 border rounded shadow-sm p-4 quiz-playground-body'
        style={{ background: "" }}
      >
        {isTestActive ? (
          <>
            {/* Header Section */}
            <QuizBodyHeader
              flaggedQuestions={flaggedQuestions}
              answers={answers}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              quizTestsQuestions={quizTestsQuestions}
              setCurrentPage={setCurrentPage}
              handleSubmit={handleSubmit}
            />

            <div className='pt-3'>
              <div className='row g-4 mx-0'>
                <div className='col-lg-6 p-4 border rounded bg-white shadow-sm'>
                  <QuizBodyQuestion
                    answers={answers}
                    handleFlagQuestion={handleFlagQuestion}
                    currentPage={currentPage}
                    quizTestsQuestions={quizTestsQuestions}
                  />
                </div>

                <div className='col-lg-6 p-4 border rounded bg-white shadow-sm d-flex flex-column justify-content-between'>
                  <QuizBodyOptions
                    answers={answers}
                    handleOptionChange={handleOptionChange}
                    currentPage={currentPage}
                    quizTestsQuestions={quizTestsQuestions}
                  />

                  <div className=' d-flex justify-content-between mt-4'>
                    {currentPage > 0 && (
                      <NextButton
                        currentPage={currentPage}
                        name={"Prev"}
                        handlePageChange={handlePageChange}
                      ></NextButton>
                    )}

                    {currentPage < quizTestsQuestions.length - 1 ? (
                      <NextButton
                        currentPage={currentPage}
                        totalQuestions={quizTestsQuestions?.length - 1}
                        name={"Next"}
                        handlePageChange={handlePageChange}
                      ></NextButton>
                    ) : (
                      <button
                        onClick={() => handleSubmit()}
                        className='btn btn-outline-primary text-center px-lg-3 '
                      >
                        Finish
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className='mt-4 p-3 border rounded bg-white shadow-sm'>
                <h5 className='text-primary'>📌 Instructions</h5>
                <ul className='text-muted text-start p-3'>
                  <li>Read the question carefully.</li>
                  <li>Select the correct option by clicking on it.</li>
                  <li>You can flag questions to review them later.</li>
                  <li>Click "Submit" when you're done.</li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          <div className='text-center py-5'>
            <h2 className='text-danger fw-bold'>Test Ended - Time's Up!</h2>
          </div>
        )}
      </div>
    </section>
  );
};

export default PlaygroundBody;
