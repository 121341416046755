import axios from 'axios';
import RCFconfig from "./config"

axios.defaults.baseURL = RCFconfig.baseURL;
axios.defaults.withCredentials = true;

export const setupAxiosInterceptors = () => {
	const onRequestSuccess = request => {
		const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token')
		const email = localStorage.getItem('access_email') || sessionStorage.getItem('access_email')
		// const currentUser = localStorage.getItem('currentUser')|| sessionStorage.getItem('currentUser')
		console.log(token);

		if (token) {
			request.headers = {
				"X-Auth-Key": email,
				"X-Auth-Secret": token,
				'Access-Control-Allow-Origin': '*',
				Authorization: `Bearer ${token}`
			}
		}

		request.withCredentials = true;  // ✅ Ensure credentials are included in each request

		return request
	}

	// const onResponseSuccess = response => response;

	// const onResponseError = err => {
	//     const status = err.status || (err.response ? err.response.status : 0);
	//     if (status === 403 || status === 401) {
	//         console.log("unautheticated");
	//     }
	//     return Promise.reject(err);
	// };

	axios.interceptors.request.use(onRequestSuccess);
	// axios.interceptors.response.use(onResponseSuccess, onResponseError);
}
