import datacodelogos from "../../../../assets/images/datacodelogo.png";
const AssessmentHeader = ({ progress, logo = datacodelogos }) => {
  return (
    <>
      <div className='row mx-0 Assessment-header mt-0 py-lg-0'>
        <div className='col-12 px-0'>
          <div className='row mx-0 align-items-center justify-content-center'>
            <div className='col-lg-6 col-12 '>
              <div className='d-flex align-items-center justify-content-center justify-content-md-start'>
                <div>
                  <img
                    src={logo}
                    alt='companylogo'
                    height={60}
                    className='m-2'
                  />
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-12 pb-2 pb-md-0'>
              <div className='d-flex gap-3 justify-content-lg-end justify-content-center align-items-center px-5'>
                {/* Progress Bar */}
                <div
                  className='rounded-5 border w-100'
                  style={{
                    width: `${progress}%`, // Dynamically set width based on progress
                    height: "6px",
                    background: `linear-gradient(to right, #3b5998 ${progress}%, #f0f0f0 ${progress}%)`,
                    // background: `linear-gradient(to right, #4caf50 ${progress}%, #f0f0f0 ${progress}%)`,
                    transition: "width 0.5s ease", // Smooth transition for width changes
                  }}
                ></div>
                {/* Displaying the progress percentage */}
                <div>{progress}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AssessmentHeader;
