const NextButton = ({ handlePageChange, name, currentPage, totalQuestions }) => {
  return (
    <>
      <div className="row mx-0">
        <div className="col-lg-12 px-0">
          <div className="align-items-center ">
            <button disabled={totalQuestions == currentPage} onClick={() => handlePageChange(name)} type="submit" className="btn btn-outline-primary px-3">{name}</button>
          </div>
        </div>
      </div>
    </>
  );
}
export default NextButton;