import parse, { domToReact } from "html-react-parser";
import { MdBookmarkAdded, MdBookmarkBorder } from "react-icons/md";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";

const QuizBodyQuestion = ({
  answers,
  handleFlagQuestion,
  quizTestsQuestions,
  currentPage,
}) => {
  const questionIndex = Math.max(
    0,
    Math.min(currentPage, quizTestsQuestions.length - 1),
  );
  const question = quizTestsQuestions?.[currentPage];
  const questionId = question?._id;

  const isFlagged = (questionId) =>
    answers.some((answer) => answer.questionId === questionId && answer.flag);

  const renderContent = (htmlString) => {
    if (!htmlString) return null;

    return parse(htmlString, {
      replace: (domNode) => {
        if (domNode.name === "pre") {
          const rawText = domNode.children
            .map((node) => (node.type === "text" ? node.data : ""))
            .join("\n");

          return (
            <SyntaxHighlighter language='html' style={atomDark}>
              {rawText.trim()}
            </SyntaxHighlighter>
          );
        }

        return domNode;
      },
    });
  };
  return (
    <section className=' bg-white rounded'>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <h5 className='fw-semibold text-dark'>Question {questionIndex + 1}</h5>
        <button
          onClick={() => handleFlagQuestion(questionId)}
          className={`btn btn-sm d-flex align-items-center gap-2 ${
            isFlagged(questionId) ? "text-primary" : "text-muted"
          }`}
        >
          {isFlagged(questionId) ? (
            <MdBookmarkAdded size={20} />
          ) : (
            <MdBookmarkBorder size={20} />
          )}
          <span className='fw-medium'>Revisit Later</span>
        </button>
      </div>
      <div className='fs-5 '>
        {question?.title && renderContent(question.title)}
      </div>
    </section>
  );
};

export default QuizBodyQuestion;
