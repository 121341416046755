import { RiEraserLine } from "react-icons/ri";

const QuizBodyOptions = ({
  handleOptionChange,
  quizTestsQuestions,
  currentPage,
  answers,
}) => {
  const { _id, option1, option2, option3, option4 } =
    quizTestsQuestions?.[currentPage] || {};
  const selectedAnswer = answers?.find(
    (answer) => answer.questionId === _id,
  )?.selectedAnswer;

  return (
    <section className=' p-0 p-md-4  '>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <h6 className='fw-semibold text-dark'>Select an option</h6>
        <button
          onClick={() => handleOptionChange(_id, null)}
          className='btn btn-sm text-danger d-flex align-items-center gap-2'
        >
          <RiEraserLine size={18} />
          <span>Clear Response</span>
        </button>
      </div>
      <div className='options-container pt-2'>
        {[option1, option2, option3, option4].map((option, optIndex) => {
          const isSelected = selectedAnswer === option;
          return (
            <div
              key={optIndex}
              onClick={() => handleOptionChange(_id, option)}
              role='button'
              tabIndex={0}
              className={`p-3 mb-2 rounded border ${
                isSelected
                  ? "border-primary bg-primary-subtle text-primary"
                  : "border-secondary-subtle"
              }`}
              style={{ transition: "0.2s", cursor: "pointer" }}
              onKeyDown={(e) =>
                e.key === "Enter" && handleOptionChange(_id, option)
              }
            >
              <div className='d-flex align-items-center gap-2'>
                <input
                  type='radio'
                  name={`question-${_id}`}
                  value={option}
                  checked={isSelected}
                  onChange={() => handleOptionChange(_id, option)}
                  id={`question-${_id}-option-${optIndex}`}
                  className='form-check-input'
                />
                <label
                  htmlFor={`question-${_id}-option-${optIndex}`}
                  className='form-check-label w-100'
                >
                  {option}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default QuizBodyOptions;
