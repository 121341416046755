import { stopTimer } from "./actionCreators";
import { getQuizTestDetails } from "../../quiz_board/actions/operations";

export const fetchQuizTestDetails = (id) => {
  return (dispatch) => {
    return dispatch(getQuizTestDetails({ quizTest: id, mode: "test" }));
  };
};

export const submitQuizTestTimer = (response) => {
  return (dispatch) => {
    dispatch(stopTimer());
  };
};
