import { MdOutlineSettings } from "react-icons/md";
// import { useDispatch, useSelector } from "react-redux";
// import { useState } from "react";
import datacodelogo from "../../../../assets/images/datacodelogo.png";
// import Timer from "../../../../shared_components/Timer";
import AssessmentTimer from "../../../Assessment/component/assessment/AssessmentTimer";

const PlayboardHeader = ({
  isTestActive,
  handleSubmit,
  handleTimeUp,
  quizTime,
  cmpLogo = datacodelogo,
}) => {
  var testUser = JSON.parse(localStorage.getItem("userDetails"));
  return (
    <>
      <section className='mt-0'>
        <div className='row mx-0 fixed PlayboardHeader-section border-bottom align-items-center mb-0 mb-md-3'>
          <div className='col-12 my-0'>
            <div className='PlayboardHeader-row-section '>
              <div className='row px-0 px-md-4 mx-0 d-flex  align-items-center justify-content-between'>
                <div className='col-lg-2  col-sm-auto col-md-2  my-0 '>
                  <div className=' d-flex gap-3 align-items-center text-sm-start justify-content-between '>
                    <img src={cmpLogo} alt='datacodelogo' height={60} />
                    <button
                      onClick={() => handleSubmit()}
                      className='btn text-center px-lg-3 datacode-primary-btn fw-bold d-block d-md-none'
                    >
                      Finish test
                    </button>
                  </div>
                </div>
                {/* <div className='col-lg-6 col-sm-auto col-md-6 my-0 '>
                  <div className='text-start align-items-center PlayboardHeader-col-2-A '>
                    <div>
                      {testUser && (
                        <span className='badge bg-primary text-white p-3 fs-5'>
                          {testUser.data.firstName} {testUser.data.lastName}
                        </span>
                      )}
                    </div>
                  </div>
                </div> */}
                <div className='col-lg-5 col-sm-auto col-md-4  my-0 PlayboardHeader-col-3'>
                  <div className='d-flex justify-content-center py-2 py-md-0 justify-content-md-end gap-3 align-items-center PlayboardHeader-col-3-A'>
                    {isTestActive && (
                      <AssessmentTimer
                        timeInMinutes={quizTime}
                        handleSubmit={handleSubmit}
                      />
                    )}

                    <div className=' align-items-center d-none d-md-block'>
                      <button
                        onClick={() => handleSubmit()}
                        className='btn  text-center px-lg-3 '
                      >
                        Finish test
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PlayboardHeader;
