import { useSelector } from "react-redux";
import Loader from "../../../shared_components/Loader";
import { Link, useParams } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const TestReport = () => {
  const { quizTestSubmittingLoading } = useSelector((state) => state.quizTest);
  const { id } = useParams();
  console.log(id);
  const details = JSON.parse(localStorage.getItem("userDetails"));
  const reports = details?.data?.quizReports;
  const quizTestReport = Array.isArray(reports)
    ? reports.find((report) => report.quizTest === id)
    : null;
  if (quizTestSubmittingLoading) {
    return <Loader />;
  }

  // Extract report data
  const correctAnswers = quizTestReport?.correctAnswers || 0;
  const wrongAnswers = quizTestReport?.wrongAnswers || 0;
  const totalScore = quizTestReport?.totalScore || 0;
  const isPassed = quizTestReport?.isPassed;
  const certificateUrl = quizTestReport?.certificateUrl;

  // Highcharts Pie Chart for Correct vs Incorrect Answers
  const barChartOptions = {
    chart: {
      type: "bar",
      height: 220,
    },
    title: {
      text: "Correct vs Incorrect Answers",
    },
    yAxis: {
      title: {
        text: "Number of Questions",
      },
      labels: {
        format: "{value}",
      },
    },
    xAxis: {
      categories: ["Correct", "Wrong"], // Labels for the y-axis
      title: {
        text: null, // Remove default y-axis title
      },
    },
    series: [
      {
        name: "Answers",
        data: [
          { y: correctAnswers, color: "#28a745" },
          { y: wrongAnswers, color: "#dc3545" },
        ],
      },
    ],
    accessibility: {
      point: {
        valueSuffix: " answers",
      },
    },
  };

  return (
    <div className='row mx-0 my-5 d-flex  flex-column justify-content-center text-center align-items-center'>
      <div className='col-11 col-md-8 border rounded-3 shadow-sm p-4 text-center bg-light '>
        <h3 className='my-3 text-dark'>
          🎉 Thank you for participating in the Quiz Test! 🎉
        </h3>

        <h2 className='m-0 p-0'>
          <p>Your Score:</p>{" "}
          <span className='text-success fw-bold' style={{ fontSize: "60px" }}>
            {totalScore}
          </span>
        </h2>

        <h4 className='my-3'>
          Result:{" "}
          <span className={`ms-1 ${isPassed ? "text-success" : "text-danger"}`}>
            {isPassed ? (
              <>
                <span className='ms-1'>Passed</span>
              </>
            ) : (
              <>
                <span>Failed</span>
              </>
            )}
          </span>
        </h4>
        {/* {isPassed && certificateUrl && (
          <p className="pt-3">
            <a
              href={certificateUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-outline-success btn-sm fw-bold px-4 py-2 rounded-pill shadow-sm d-inline-flex align-items-center"
            >
              View Certificate
              <i className="fas fa-external-link-alt ms-2"></i>
            </a>
          </p>
        )} */}
      </div>
      <div className='col-11 col-md-8 border mt-3 rounded-3 px-0'>
        <HighchartsReact highcharts={Highcharts} options={barChartOptions} />
      </div>
      <div className='col-11 col-md-8 border mt-3 rounded-3 bg-light '>
        <p className='footer-note pt-3'>
          We appreciate your effort. Keep improving!
        </p>
        <Link to='/quiz/tests'>
          <p className='mt-3'>Back to Tests</p>
        </Link>
      </div>
    </div>
  );
};

export default TestReport;
